<template>
  <div id="top">
    <el-row>
      <!-- logo及导航 -->
      <el-col :span="17">
        <div class="grid-content bg-purple">
          <img src="../../../assets/logo.png"/>
        </div>
        <!-- 导航 -->
        <div class="grid-content bg-purple">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#fff0"
            text-color="#fff"
            active-text-color="#ffd200"
          >
            <el-menu-item index="1">首页</el-menu-item>     
            <el-menu-item index="2">      
              <!-- 子菜单 -->
              <el-dropdown  @command="handleCommand">
                <span class="el-dropdown-link">
                公司简介
                </span>
                <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="2-1">公司简介</el-dropdown-item>                  
                  <el-dropdown-item command="2-2">发展历程</el-dropdown-item>                  
                  <el-dropdown-item command="2-3">使命与责任</el-dropdown-item>                  
                  <el-dropdown-item command="2-4">资质认证</el-dropdown-item>                  
                  <el-dropdown-item command="2-5">联系我们</el-dropdown-item>
                </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-menu-item>            
            <el-menu-item index="3">产品中心</el-menu-item>            
            <el-menu-item index="4">解决方案</el-menu-item>            
            <el-menu-item index="5">新闻中心</el-menu-item>            
            <el-menu-item index="6">人才招聘</el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <!-- 电话微信搜索 -->
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <!-- 电话 -->
          <p>029-81148661</p>
          <!-- 微信 -->
          <div id="wxBox">
            <img src="../../../assets/wx.png" style="float: left;"/>
            <img id="wxCode" src="../../../assets/erweima.jpg" style="width: 110px; heigth: 110px;"/>
          </div>
          <!-- 搜索 -->
          <div id="seachBox" @click="handleSeach">
            <img src="../../../assets/search.png" style="float: left;"/>
          </div>
        </div>
      </el-col>
    </el-row> 
    <div id="seachInput" v-show="seachShow">
      <input type="text" placeholder="请输入搜索内容"/>
      <div id="inputImg" @click="inputSeach">
        <img src="../../../assets/search1.png">
      </div>
    </div> 
  </div>

</template>

<script>
	export default {
    name: 'TopBar',
		props: {
      activeIndex: { default: '1'},   // 导航默认下标
      
      },
		data () {
			return {
        seachShow: false    // 搜索条显示 默认 false 不显示
      }
		},
		methods: {
      // 导航点击事件
      handleSelect(key) {
        switch (key) {
          case '1':
            this.$router.push('/index');
            break;
          case '2':
            this.$router.push('/profile');
            break;
          case '3':
            this.$router.push('/product');
            break;
          case '4':
            this.$router.push('/solution');
            break;
          case '5':
            this.$router.push('/news');
            break;
          case '6':
            this.$router.push('/recruit');
            break;
        }
      },
      // 子菜单点击事件
      handleCommand(command) {
        this.activeIndex == '2' ? this.activeIndex = '2': true;
        switch (command) {
          case '2-1':
            this.$router.push('/profile/abouts');
            break;
          case '2-2':
            this.$router.push('/profile/progress');
            break;
          case '2-3':
            this.$router.push('/profile/duty');
            break;
          case '2-4':
            this.$router.push('/profile/immp');
            break;
          case '2-5':
            this.$router.push('/profile/contact');
            break;
        }
      },
      // 搜索显示
      handleSeach() {
        this.seachShow = true;
      },
      // 搜索提交
      inputSeach() {
        alert('请输入你要检索的内容！')
      }
    }
	}
</script>

<style scoped>
div.el-row {
  border-bottom: 1px solid rgba(255,255,255, 0.3);
  position: relative;
}
/* logo及导航 */
div.el-row div.el-col.el-col-17 {
  box-sizing: border-box;
  display: flex;
  /* flex-wrap: nowrap; */
}
div.el-row div.el-col.el-col-17 div.grid-content.bg-purple img {
  padding: 1.5rem;
}
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 106px;
  line-height: 106px;
  margin: 0;
  border-bottom: 1px solid transparent;
  font-size: 1rem;
}
div.el-row div.el-col.el-col-17 div.grid-content.bg-purple ul.el-menu.el-menu--horizontal.el-menu-demo {
  line-height: 100px;
  text-align: center;
  font-size: 1rem;
  outline: none;
  border: 0;
  margin-left: 5rem;
  display: flex;
  z-index: 300;
}
/* 电话微信搜索 */
div.el-row div.el-col.el-col-6 {
  padding: 0;
  line-height: 106px;
  position: relative;
}
/* 电话 */
div.el-row div.el-col.el-col-6 p {
  position: absolute;
  color: rgba(255,255,255, 0.6);
  background: url('../../../assets/iphone.png') no-repeat left center;
  padding-left: 22px;
  line-height: 106px;
  display: block;
  font-size: 0.875rem;
}
/* 微信 */
#wxBox {
  position: absolute;
  top: 38px;
  left: 135px;
}
#wxCode {
  display: none;
}
#wxBox:hover #wxCode {
  display: block;
  position: absolute;
  z-index: 1100;
  top: 34px;
  left: -40px;
}
/* 搜索 */
#seachBox {
  position:absolute;
  top: 38px;
  left: 180px;
}
#seachInput {
  position: relative;
  width: calc(100% - 500px);
  float: left;
  line-height: 63px;
  height: 63px;
  border: none;
  padding-left: 20px;
  top: 10px;
  left: 200px;
}
#seachInput > input {
  position: relative;
  outline: none;
  width: calc(100% - 100px);
  float: left;
  line-height: 63px;
  height: 63px;
  background: rgba(255,255,255, 0.8);
  border: none;
  padding-left: 20px;
}
#seachInput > input::placeholder {
  font-size: 14px;
}
#seachInput > #inputImg {
  width: 13px;
  padding: 0 30px;
  display: block;
  float: left;
  background: #0066E4;
  border: none;
}
</style>
  